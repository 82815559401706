import { useAnimation } from 'framer-motion';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';

import Button from 'components/Button/Button';
import ContentMedia from 'components/ContentMedia/ContentMedia';
import RichText from 'components/RichText/RichText';
import VideoBlock from 'components/VideoBlock/VideoBlock';
import {
  MediaCMS,
  MediaType,
  VideoTypesCMS,
} from 'utils/sharedStrapiQueries/sharedTypes';

import { carouselTextAnimations } from '../VisualCarousel.animations';
import animationData from './DBP_combo_v4.json';

import * as S from './IntroSlide.styles';

export interface IntroSlideProps {
  media: MediaCMS;
  mediaType: MediaType;
  videoErrorMessage: string;
  videoType: VideoTypesCMS;
  setIsIntroSlides: (value: boolean) => void;
  introSupportingText: string;
  introSupportingButton: { label: string; href: string };
  secondIntroSupportingText: string;
  secondIntroSupportingButton: { label: string; href: string };
}

const IntroSlide: React.FunctionComponent<IntroSlideProps> = ({
  media,
  mediaType,
  videoErrorMessage,
  videoType,
  setIsIntroSlides,
  introSupportingText,
  introSupportingButton,
  secondIntroSupportingText,
  secondIntroSupportingButton,
  ...rest
}) => {
  const lottieRef = useRef<HTMLDivElement>(null);
  const animate = useAnimation();
  const second_animate = useAnimation();

  const handleFrame = e => {
    if (Math.round(e.currentTime) === 0)
      animate.start(carouselTextAnimations.initial),
        second_animate.start(carouselTextAnimations.initial);

    if (
      Math.round(e.currentTime) === 230 ||
      (Math.round(e.currentTime) >= 227 && Math.round(e.currentTime) <= 230)
    ) {
      animate.start(carouselTextAnimations.enter);
    }

    if (Math.round(e.currentTime) === 465)
      animate.start(carouselTextAnimations.exit);
    if (Math.round(e.currentTime) === 530)
      second_animate.start(carouselTextAnimations.enter);
  };

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData,
    });

    anim.addEventListener('drawnFrame', handleFrame);
    anim.addEventListener('loopComplete', () => setIsIntroSlides(false));

    return () => anim.destroy();
  }, []);

  return (
    <>
      <S.ImageWrapper>
        {videoType?.source === 'azure' || videoType?.source === 'vimeo' ? (
          <VideoBlock
            videoSource={videoType?.source}
            videoSourceId={videoType?.sourceId}
            azureUserId={videoType?.azureUserId}
            autoplay={videoType?.autoplay}
            manualPlay={videoType?.manualPlay}
            mute={videoType?.mute}
            withSound={videoType?.withSound}
            azurePlaceholder={videoType?.azurePlaceholder}
            vimeoBackground={videoType?.vimeoBackground}
            disableInView
          />
        ) : (
          <ContentMedia
            media={media}
            mediaType={mediaType}
            videoErrorMessage={videoErrorMessage}
            autoplay={videoType?.autoplay}
            mute={videoType?.mute}
          />
        )}
      </S.ImageWrapper>

      <S.IntroSlideWrapper {...rest}>
        <S.MiddleTextWrapper>
          <div className="animation-container" ref={lottieRef} />
        </S.MiddleTextWrapper>

        <S.BottomTextWrapper
          animate={animate}
          initial={{ opacity: 0, display: 'none' }}
        >
          {introSupportingText && (
            <RichText
              as="h3"
              size="heading3"
              richText={introSupportingText}
              defaultFormatting
            />
          )}
          {introSupportingButton?.label && (
            <Button
              label={introSupportingButton?.label}
              href={introSupportingButton?.href}
              transparent
            />
          )}
        </S.BottomTextWrapper>

        <S.BottomTextWrapper
          animate={second_animate}
          initial={{ opacity: 0, display: 'none' }}
        >
          {secondIntroSupportingText && (
            <RichText
              as="h3"
              size="heading3"
              richText={secondIntroSupportingText}
              defaultFormatting
            />
          )}
          {secondIntroSupportingButton?.label && (
            <Button
              label={secondIntroSupportingButton?.label}
              href={secondIntroSupportingButton?.href}
              transparent
            />
          )}
        </S.BottomTextWrapper>
      </S.IntroSlideWrapper>
    </>
  );
};

export default IntroSlide;
