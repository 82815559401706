import React, { FunctionComponent } from 'react';

import Button from 'components/Button/Button';
import ContentMedia from 'components/ContentMedia/ContentMedia';
import RichText from 'components/RichText/RichText';
import useOverElement from 'hooks/useOverElement';
import { ROUTES } from 'utils/routes';
import { MediaCMS } from 'utils/sharedStrapiQueries/sharedTypes';

import * as S from './ImageArticle.styles';

export interface ImageArticleProps {
  headline: string;
  articles: {
    id: number;
    text: string;
  }[];
  buttonText: string;
  media: MediaCMS;
}

const defaultProps: Partial<ImageArticleProps> = {
  headline: '',
  articles: [],
  buttonText: '',
  media: { url: '' },
};

const ImageArticle: FunctionComponent<ImageArticleProps> = ({
  headline,
  articles,
  buttonText,
  media,
  ...rest
}) => {
  const [ref] = useOverElement();

  const articlesContent = (
    <S.Articles>
      {articles.map(({ id, text }) => (
        <RichText
          key={id}
          as="p"
          size="smallBody"
          richText={text}
          defaultFormatting
        />
      ))}
    </S.Articles>
  );

  return (
    <S.Wrapper ref={ref} {...rest}>
      <S.Container>
        <S.Headline>
          <RichText
            as="h3"
            size="heading3"
            richText={headline}
            defaultFormatting
          />
          {articlesContent}
          <Button label={buttonText} href={ROUTES.JOIN_US} />
        </S.Headline>

        <S.ImageWrapper>
          <ContentMedia media={media} mediaType="image" />
        </S.ImageWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

ImageArticle.defaultProps = defaultProps;

export default ImageArticle;
