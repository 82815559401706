import React, { FunctionComponent, memo } from 'react';

import ContentMedia from 'components/ContentMedia/ContentMedia';
import RichText from 'components/RichText/RichText';
import VideoBlock from 'components/VideoBlock/VideoBlock';
import { ROUTES } from 'utils/routes';

import { VisualCarouselSlidesProps } from '../VisualCarousel';

import * as S from './VisualCarouselSlide.styles';

export interface VisualCarouselSlideProps extends VisualCarouselSlidesProps {}

const defaultProps: Partial<VisualCarouselSlideProps> = {};

const VisualCarouselSlide: FunctionComponent<VisualCarouselSlideProps> = ({
  media,
  mediaType,
  question,
  clientName,
  caseStudy,
  buttonText,
  videoErrorMessage,
  source,
  sourceId,
  azureUserId,
  azurePlaceholder,
  autoplay,
  manualPlay,
  mute,
  withSound,
  vimeoBackground,
}) => {
  return (
    <>
      <S.ImageWrapper>
        {source === 'azure' || source === 'vimeo' ? (
          <VideoBlock
            videoSource={source}
            videoSourceId={sourceId}
            azureUserId={azureUserId}
            autoplay={autoplay}
            manualPlay={manualPlay}
            mute={mute}
            withSound={withSound}
            azurePlaceholder={azurePlaceholder}
            vimeoBackground={vimeoBackground}
          />
        ) : (
          <ContentMedia
            media={media}
            mediaType={mediaType}
            videoErrorMessage={videoErrorMessage}
            autoplay={autoplay}
            mute={mute}
          />
        )}
      </S.ImageWrapper>

      <S.SlideOverlay>
        <S.Container>
          {question && (
            <S.Question>
              <RichText
                as="h1"
                size="heading1"
                richText={question}
                defaultFormatting
              />
            </S.Question>
          )}

          <S.CaseStudyWrapper>
            <S.CarouselTextWrapper fixCorner={!question}>
              <RichText
                as="h4"
                size="heading4"
                richText={clientName}
                defaultFormatting
              />
            </S.CarouselTextWrapper>
            {buttonText && (
              <S.CarouselButton
                label={buttonText}
                href={ROUTES.WORK + '/' + caseStudy?.slug}
                transparent
                fixBottom={!question}
              />
            )}
          </S.CaseStudyWrapper>
        </S.Container>
      </S.SlideOverlay>
    </>
  );
};

VisualCarouselSlide.defaultProps = defaultProps;

export default memo(VisualCarouselSlide);
