import styled from 'styled-components';

import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

interface WrapperProps {}

const { min } = layout.columns;

export const Wrapper = styled.section<WrapperProps>`
  /* margin: 0 0 274px;

  ${mediaTablet(`
    margin: 0 0 320px;
`)} */
`;
// 12
export const Container = styled.div`
  display: grid;
  grid-row-gap: 64px;
  grid-template-columns: 1fr;

  ${mediaDesktop(`
    grid-gap: 0 72px;
    grid-template-columns: repeat(${min}, 1fr);
  `)};
`;

export const Headline = styled.div`
  height: fit-content;
  display: grid;
  grid-row-gap: 24px;

  ${mediaTablet(`
    grid-row-gap: 48px;
  `)};
`;

export const Articles = styled.div`
  display: grid;
  grid-gap: 24px 0;
  grid-template-columns: 1fr;

  ${mediaTablet(`
    grid-gap: 0 72px;
    grid-template-columns: repeat(${min}, 1fr);
  `)};
`;

export const ImageWrapper = styled.div`
  display: grid;
`;
