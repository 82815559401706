import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import Carousel from 'components/Carousel/Carousel';
import DoubleColorHeadline from 'components/DoubleColorHeadline/DoubleColorHeadline';
import ImageArticle from 'components/ImageArticle/ImageArticle';
import NumberCards from 'components/NumberCards/NumberCards';
import RedirectionFilters from 'components/RedirectionFilters/RedirectionFilters';
import VisualCarousel from 'components/VisualCarousel/VisualCarousel';
import { setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';

interface WrapperProps {
  headlineHeight: number;
  isFirstVisualCarousel?: boolean;
}

interface IndexPageHeadlineProps {
  heightValue?: number;
}

const defaultColumns = css`
  grid-column: main-section / out-right;
`;

export const Wrapper = styled(motion.div)<WrapperProps>`
  min-height: ${setVh(100)};
  padding: ${({ headlineHeight, isFirstVisualCarousel }) =>
    isFirstVisualCarousel
      ? `padding: 0 0 154px; max-height: ${setVh(100)};`
      : `calc(${setVh(100)} - ${headlineHeight}px - 40px - 60px) 0 154px;`};

  display: grid;
  grid-template-columns: [out-left] 16px [main-section] auto [out-right] 16px;

  ${({ headlineHeight, isFirstVisualCarousel }) =>
    mediaTablet(`
      padding: ${
        isFirstVisualCarousel
          ? '0 0 154px'
          : `calc(${setVh(100)} - ${headlineHeight}px - 80px - 60px) 0 154px`
      };
    `)}

  ${mediaDesktop(`
      grid-template-columns: [out-left] 124px [main-section] auto [out-right] 124px;
    `)}
`;

export const IndexPageHeadline = styled(
  DoubleColorHeadline
)<IndexPageHeadlineProps>`
  ${({ heightValue }) =>
    heightValue &&
    `
    height: ${setVh(heightValue)};
    display: grid;
    place-content: center;
  `};
  ${defaultColumns}
`;

export const IndexPageCarousel = styled(Carousel)`
  min-height: ${setVh(100)};
  grid-column: 1/-1;
`;

export const IndexPageVisualCarousel = styled(VisualCarousel)`
  height: ${setVh(100)};
  grid-column: 1/-1;
`;

export const IndexPageRedirectionFilters = styled(RedirectionFilters)`
  ${defaultColumns}
`;

export const IndexPageNumberCards = styled(NumberCards)`
  ${defaultColumns}
`;

export const IndexPageImageArticle = styled(ImageArticle)`
  ${defaultColumns}
`;
