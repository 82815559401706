import chunk from 'lodash/chunk';
import uniqueId from 'lodash/uniqueId';
import React, { FunctionComponent, useEffect, useState } from 'react';

import AnimatedArrow from 'components/AnimatedArrow/AnimatedArrow';
import Button from 'components/Button/Button';
import LinkComp from 'components/LinkComp/LinkComp';
import Overlay from 'components/Overlay/Overlay';
import CapabilitiesOverlay from 'components/Overlays/CapabilitiesOverlay/CapabilitiesOverlay';
import RichText from 'components/RichText/RichText';
import Text from 'components/Text/Text';
import useOverElement from 'hooks/useOverElement';
import { useCopyStore, useGlobalStore } from 'store';
import { handleMouseEnter, handleMouseLeave } from 'utils/cursorGlobal';
import { ROUTES } from 'utils/routes';
import { ExpertiseCategoryCMS } from 'utils/sharedStrapiQueries/sharedTypes';

import * as S from './RedirectionFilters.styles';

export interface RedirectionFiltersProps {
  headline?: string;
  buttonText?: string;
  filters: {
    filter: {
      id: number;
      tag: string;
    };
    overlay?: {
      expertiseCategory: ExpertiseCategoryCMS;
    };
    overlayEnabled?: boolean;
  }[];
}

const defaultProps: Partial<RedirectionFiltersProps> = {
  filters: [],
};

const RedirectionFilters: FunctionComponent<RedirectionFiltersProps> = ({
  headline,
  buttonText,
  filters,
  ...rest
}) => {
  const [overlayData, setOverlayData] = useState(null);
  const [category, setCategory] = useState(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { globalSettings } = useCopyStore(({ copy }) => copy);
  const { activeFilter, setActiveFilter } = useGlobalStore();
  const chunks = chunk(filters, filters.length / 2 + 1);

  const [ref] = useOverElement();

  useEffect(() => {
    if (overlayData) {
      const data = overlayData?.expertiseCategory;
      const filteredOverlayData = data?.listItems?.filter(
        ({ filter }) =>
          filter?.tag.toLocaleLowerCase() === activeFilter.toLocaleLowerCase()
      )[0];

      setCategory({
        ...filteredOverlayData,
        relatedInsights: data.relatedInsights,
        relatedCaseStudies: data.relatedCaseStudies,
      });
    }
  }, [overlayData, activeFilter]);

  return (
    <>
      <S.Wrapper ref={ref} {...rest}>
        <S.Container fullColumns={!headline && !buttonText}>
          {(headline || buttonText) && (
            <S.Headline>
              {headline && (
                <RichText
                  as="h3"
                  size="heading3"
                  richText={headline}
                  defaultFormatting
                />
              )}
              {buttonText && <Button label={buttonText} href={ROUTES.WORK} />}
            </S.Headline>
          )}

          <S.Filters
            halfPosition={!headline && !buttonText}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {chunks.map(arr =>
              arr.map(({ filter, overlay, overlayEnabled }) => (
                <S.Filter
                  key={uniqueId()}
                  onClick={() => setActiveFilter(filter?.tag)}
                  hoverColor={
                    globalSettings?.buttonsHoverColor ||
                    globalSettings?.buttonsHoverDefaultColors
                  }
                >
                  {overlayEnabled ? (
                    <S.Content
                      onClick={() => (setOverlayData(overlay), setIsOpen(true))}
                    >
                      <Text as="span" size="smallBody" text={filter?.tag} />
                      <AnimatedArrow />
                    </S.Content>
                  ) : (
                    <LinkComp
                      href={
                        ROUTES.WORK +
                        `?filter=${encodeURIComponent(filter?.tag)}`
                      }
                      isCursorHoverState={false}
                    >
                      <Text as="span" size="smallBody" text={filter?.tag} />
                      <AnimatedArrow />
                    </LinkComp>
                  )}
                </S.Filter>
              ))
            )}
          </S.Filters>
        </S.Container>
      </S.Wrapper>

      <Overlay isOpen={isOpen} onExitClick={() => setIsOpen(false)}>
        <CapabilitiesOverlay {...category} />
      </Overlay>
    </>
  );
};

RedirectionFilters.defaultProps = defaultProps;

export default RedirectionFilters;
