import React, { FunctionComponent } from 'react';

import RichText from 'components/RichText/RichText';
import Text from 'components/Text/Text';

import * as S from './Card.styles';

export interface CardProps {
  title: string;
  subtitle: string;
  number: number;
  titleColor?: string;
  titleDefaultColors?: string;
}

const defaultProps: Partial<CardProps> = {};

const Card: FunctionComponent<CardProps> = ({
  title,
  subtitle,
  number,
  titleColor,
  titleDefaultColors,
  ...rest
}) => {
  return (
    <S.Wrapper {...rest}>
      <S.Top>
        <Text
          as="h4"
          size="heading4"
          text={number <= 9 ? `0${number}` : `${number}`}
          color={titleColor || titleDefaultColors}
        />
        <Text
          as="h4"
          size="heading4"
          text={title}
          color={titleColor || titleDefaultColors}
        />
      </S.Top>

      <RichText as="p" size="smallBody" richText={subtitle} defaultFormatting />
    </S.Wrapper>
  );
};

Card.defaultProps = defaultProps;

export default Card;
