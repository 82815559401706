export const carouselAnimations = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.6,
    },
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.6,
    },
  },
};

export const carouselTextAnimations = {
  initial: {
    opacity: 0,
    transition: {
      duration: 0.6,
    },
    transitionEnd: { display: 'none' },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.6,
    },
    transitionEnd: { display: 'none' },
  },
  enter: {
    opacity: 1,
    display: 'flex',
    transition: {
      duration: 0.6,
    },
  },
};
