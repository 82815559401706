import styled from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';
import { layout } from 'utils/styles/theme';

interface WrapperProps {}

const { fourThirds, max } = layout.columns;

export const Wrapper = styled.section<WrapperProps>`
  margin: 0 0 120px;

  ${mediaTablet(`
      margin: 0 0 250px;
  `)}
`;

export const Container = styled.div`
  height: 100%;
  display: grid;
  grid-row-gap: 64px;
  grid-template-columns: 1fr;

  ${mediaTablet(`
    grid-row-gap: 56px;
  `)};
`;

export const Headline = styled.div`
  display: grid;
  grid-template-columns: repeat(${max}, minmax(0px, 1fr));

  h3 {
    grid-column: 1/-1;
  }

  ${mediaTablet(`
    h3 {
      grid-column: 1/7;
    }
  `)};
`;

export const Cards = styled.div`
  display: grid;
  grid-gap: 64px 0;
  grid-template-columns: 1fr;

  ${mediaTablet(`
    grid-gap: 0 64px;
    grid-template-columns: repeat(${fourThirds}, minmax(0px, 1fr));
  `)};
`;
