import { motion } from 'framer-motion';
import styled from 'styled-components';

import { MediaWrapper } from 'components/VideoBlock/VideoBlock.styles';
import { mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

export const IntroSlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;

  button {
    background-color: ${colors.codGray}1A;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;

  ${MediaWrapper} {
    height: 100%;
  }
`;

export const MiddleTextWrapper = styled.div`
  position: absolute;
  top: 41.905%;
  left: 19px;
  right: 19px;

  ${mediaTablet(`
    width: 45%;
    right: auto;
  `)}
`;

export const BottomTextWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;

  padding: 0 39px 56px;

  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 32px;
  }

  ${mediaTablet(`
    flex-direction: row;

    h3 {
      margin: 0 32px 0 0;
    }
  `)}
`;
