import React, { FunctionComponent } from 'react';

import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import RichText from 'components/RichText/RichText';
import useOverElement from 'hooks/useOverElement';
import { ROUTES } from 'utils/routes';

import * as S from './NumberCards.styles';

export interface NumberCardsProps {
  headline: string;
  numberCards: {
    id: number;
    title: string;
    subtitle: string;
    titleColor?: string;
    titleDefaultColors?: string;
  }[];
  buttonText: string;
}

const defaultProps: Partial<NumberCardsProps> = {
  headline: '',
  numberCards: [],
  buttonText: 'About us',
};

const NumberCards: FunctionComponent<NumberCardsProps> = ({
  headline,
  numberCards,
  buttonText,
  ...rest
}) => {
  const [ref] = useOverElement();

  return (
    <S.Wrapper ref={ref} {...rest}>
      <S.Container>
        <S.Headline>
          <RichText
            as="h3"
            size="heading3"
            richText={headline}
            defaultFormatting
          />
        </S.Headline>

        <S.Cards>
          {numberCards.map((props, index) => (
            <Card key={props.id} number={index + 1} {...props} />
          ))}
        </S.Cards>

        <Button label={buttonText} href={ROUTES.ABOUT} />
      </S.Container>
    </S.Wrapper>
  );
};

NumberCards.defaultProps = defaultProps;

export default NumberCards;
