import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setVh } from 'utils/styles/mixins';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors } from 'utils/styles/theme';

interface WrapperProps {}

interface ArrowsWrapperProps {
  hoverFillColor: string;
}

export interface SlidesProps {}

export const Wrapper = styled.section<WrapperProps>`
  max-width: 100vw;
  max-height: ${setVh(100)};
  position: relative;

  margin: 0 0 120px;

  ${mediaTablet(`
    margin: 0 0 238px;
  `)};
`;

export const Slides = styled(motion.div)<SlidesProps>`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
`;

export const Motion = styled(motion.div)`
  min-width: 100%;

  position: relative;
  flex: 1;
`;

export const ArrowsWrapper = styled.div<ArrowsWrapperProps>`
  position: absolute;

  bottom: 56px;

  svg {
    max-width: 46px;
    max-height: 46px;

    path:first-child {
      fill: ${colors.codGray}1A;
      transition: fill 0.3s ease, stroke 0.3s ease;
    }

    user-select: none;
    cursor: pointer;

    &:active {
      transform: scale(1.05);
    }

    ${({ hoverFillColor }) =>
      hoverFillColor &&
      css`
        &:hover {
          path {
            &:nth-of-type(1) {
              fill: ${colors.concrete};
            }

            &:nth-of-type(2) {
              stroke: ${colors[hoverFillColor] ?? hoverFillColor} !important;
            }
          }
        }
      `}
  }
`;

export const LeftArrow = styled(ArrowsWrapper)`
  left: 41px;

  ${mediaDesktop(`
    left: 82px;
  `)}
`;

export const RightArrow = styled(ArrowsWrapper)`
  right: 41px;

  ${mediaDesktop(`
    right: 82px;
  `)}
`;
