import debounce from 'lodash/debounce';
import { GetStaticProps } from 'next';
import { Router } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import SEO from 'components/SEO/SEO';
import { useCopyStore, useGlobalStore } from 'store';
import { CopyStoreType } from 'store/copy.types';
import { queryCMS } from 'utils/cms/api';
import { indexPageQuery } from 'utils/cms/gql';
import { ISR_TIMEOUT } from 'utils/config';
import { ROUTES } from 'utils/routes';
import { marketPageId, MarketPages } from 'utils/sharedStrapiQueries/market';
import { pageMotionProps } from 'utils/styles/animations';

import { DataCMSTypes, IndexPageDataCMS } from './IndexPage.types';

import * as S from './IndexPage.styles';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const id = await marketPageId(MarketPages.homePage, locale);
  const cookieId = await marketPageId(MarketPages.cookieBar, locale);
  const footerId = await marketPageId(MarketPages.footer, locale);

  const globalIds = { cookieId, footerId };

  const response = await queryCMS<DataCMSTypes>({
    query: indexPageQuery({ id, locale, globalIds }),
  });

  const {
    data: {
      sharedCopies,
      homePage: page,
      redirectPopups,
      cookieBar,
      navbar,
      footer,
    },
  } = response;

  if (!page || !sharedCopies) return { notFound: true };

  return {
    props: {
      initialCopy: {
        head: page?.seo || null,
        global: sharedCopies[0]?.global || null,
        globalSettings: sharedCopies[0] || null,
        redirectPopup: redirectPopups[0] || null,
        cookieBar: cookieBar || null,
        navbar,
        footer,
      },
      page,
    },
    revalidate: ISR_TIMEOUT,
  };
};

type ModuleListProps = {
  [key: string]: React.FunctionComponent<any>;
};

interface IndexPageProps {
  initialCopy: {
    head: CopyStoreType['copy']['head'];
    global: CopyStoreType['copy']['global'];
    globalSettings: CopyStoreType['copy']['globalSettings'];
    redirectPopup: CopyStoreType['copy']['redirectPopup'];
    cookieBar: CopyStoreType['copy']['cookieBar'];
    navbar: CopyStoreType['copy']['navbar'];
    footer: CopyStoreType['copy']['footer'];
  };
  page: IndexPageDataCMS;
  router: Router;
}

const IndexPage: React.FunctionComponent<IndexPageProps> = ({
  initialCopy,
  page,
}) => {
  const [firstIsLoaded, setFirstIsLoaded] = useState<boolean>(false);
  const [headlineHeight, setHeadlineHeight] = useState<number>(200);

  const { setCopy } = useCopyStore();
  const { setHeaderText } = useGlobalStore();

  const { width } = useWindowSize();

  useEffect(() => {
    setCopy(initialCopy);
    setHeaderText({
      default:
        initialCopy.navbar.navLinks.find(({ href }) => href === ROUTES.HOME)
          ?.headerText || initialCopy.navbar?.hoveredText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCopy]);

  useEffect(() => {
    const timeout = setTimeout(
      debounce(() => {
        const element = document.querySelectorAll(
          S.IndexPageHeadline.toString()
        )[0];

        setHeadlineHeight(element?.clientHeight);
      }, 500),
      100
    );

    return () => clearTimeout(timeout);
  }, [width, firstIsLoaded]);

  const modulesList: ModuleListProps = {
    ComponentSectionsDoubleColorHeadline: S.IndexPageHeadline,
    ComponentSectionsCarousel: S.IndexPageCarousel,
    ComponentSectionsVisualCarousel: S.IndexPageVisualCarousel,
    ComponentSectionsRedirectionFilters: S.IndexPageRedirectionFilters,
    ComponentSectionsNumberCards: S.IndexPageNumberCards,
    ComponentSectionsImageArticle: S.IndexPageImageArticle,
  };

  const renderModules = useMemo(
    () =>
      page.modules.map((moduleData, index) => {
        const moduleName: string = moduleData.__typename;
        const moduleId: number = Math.random();

        const ModuleComponent = modulesList[moduleName];

        if (index === 0) setFirstIsLoaded(true);

        if (!ModuleComponent) return null;

        return (
          <ModuleComponent key={`${moduleName}${moduleId}`} {...moduleData} />
        );
      }),
    [page]
  );

  const isFirstVisualCarousel = page?.modules[0]?.__typename.includes(
    'ComponentSectionsVisualCarousel'
  );

  if (!page) return null;

  return (
    <S.Wrapper
      headlineHeight={headlineHeight}
      isFirstVisualCarousel={isFirstVisualCarousel}
      {...pageMotionProps}
    >
      {page?.seo && <SEO seo={page.seo} />}

      {renderModules}
    </S.Wrapper>
  );
};

export default IndexPage;
