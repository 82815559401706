import styled from 'styled-components';

import BaseFilter from 'components/Filter';
import { mediaDesktop, mediaTablet } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface WrapperProps {}

interface ContainerProps {
  fullColumns: boolean;
}

interface FiltersProps {
  halfPosition: boolean;
}

export interface FilterProps {
  hoverColor?: string;
}

const { min, max } = layout.columns;

export const Wrapper = styled.section<WrapperProps>`
  margin: 0 0 120px;

  ${mediaTablet(`
    margin: 0 0 247px;
  `)};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-row-gap: 64px;
  grid-template-columns: 1fr;

  ${({ fullColumns }) =>
    mediaDesktop(`
    grid-gap: 0;
    grid-template-columns: repeat(${fullColumns ? max : min}, 1fr);
  `)}
`;

export const Headline = styled.div`
  height: fit-content;
  display: grid;
  grid-row-gap: 24px;

  ${mediaTablet(`
    grid-template-columns: repeat(${max}, 1fr);
    grid-row-gap: 48px;

    h3 {
      grid-column: 1/${max};
    }
  `)};
`;

export const Filters = styled.div<FiltersProps>`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center start;

  ${({ halfPosition }) =>
    mediaTablet(`
      ${halfPosition && 'grid-column: 6 / 13'};
      grid-template-columns: repeat(${min}, 1fr);
    `)}
`;

export const Filter = styled(BaseFilter)<FilterProps>`
  ${({ hoverColor }) =>
    hoverColor &&
    mediaDesktop(`
    transition: color 0.4s ease-out;

    span {
      transition: color 0.4s ease-out;
    }
        &:hover {
          color: ${colors[hoverColor] ?? hoverColor} !important;
          span {
            color: ${colors[hoverColor] ?? hoverColor} !important;
          }
        }
  `)}
`;
